import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BudgetLineItemTableHeaderComponent } from '../budget-line-item-table-header/budget-line-item-table-header.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { SPEND_TYPES } from '../../../../../../framework/constants/budget.constants';
import { BudgetLineItemComponent } from '../budget-line-item/budget-line-item.component';
import {
  getAllCommittedItems,
  getDisableBasedOnTemplate,
  getLineItemsExtended,
  getProjectStartDate,
  getSelectedSpendType,
  getSelectedYear,
  getStoreUpdates,
} from '../../../../../../store/spend/spend.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import {
  ICommittedItem,
  ICommittedItemExtended,
  ILastSpendStoreUpdate,
  ILineItem,
  ILineItemExtended,
  SpendStoreUpdateTypes,
} from '../../../../../../store/spend/spend.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import {
  updateCommittedLineItemsBulk,
  updateLineItem,
  updateLineItemsBulk,
} from '../../../../../../store/spend/spend.actions';
import { DeepCopyService } from '../../../../../../services/deep-copy.service';
import { BudgetLineItemTotalsComponent } from '../budget-line-item-totals/budget-line-item-totals.component';
import { CommittedLineItemComponent } from '../budget-line-item/committed-line-item/committed-line-item.component';
import { CdkScrollable } from '@angular/cdk/overlay';
import { SpendDistributionService } from '../../../../../../services/spend-distribution.service';

@Component({
  selector: 'app-budget-line-item-table',
  standalone: true,
  imports: [
    BudgetLineItemTableHeaderComponent,
    CdkDrag,
    NgForOf,
    AsyncPipe,
    BudgetLineItemComponent,
    CdkDropList,
    NgClass,
    BudgetLineItemTotalsComponent,
    CommittedLineItemComponent,
    NgIf,
    CdkScrollable,
  ],
  templateUrl: './budget-line-item-table.component.html',
  styleUrl: './budget-line-item-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetLineItemTableComponent implements OnInit, OnDestroy {
  protected readonly store = inject(Store);
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly spentDistributionService = inject(SpendDistributionService);

  protected readonly isDestroyed$ = new Subject<boolean>();
  protected readonly selectedYear$ = this.store.select(getSelectedYear);
  protected readonly lastStoreUpdate$ = this.store.select(getStoreUpdates);
  protected readonly projectStartDate$ = this.store.select(getProjectStartDate);
  protected readonly disableOnTemplate$ = this.store.select(getDisableBasedOnTemplate);
  protected readonly selectedBudgetType$ = this.store.select(getSelectedSpendType);
  protected readonly committedItems$: Observable<ICommittedItemExtended[]> =
    this.store.select(getAllCommittedItems);
  protected readonly SPEND_TYPES = SPEND_TYPES;
  isDragging = false;
  items: ILineItemExtended[] = [];
  committedItems: ICommittedItemExtended[] = [];
  projectStartDate: string;
  selectedYear: number;

  @Input({ required: true }) templateId: number;

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {
    this.lastStoreUpdate$
      // todo: consider using less debounce time
      .pipe(takeUntil(this.isDestroyed$), debounceTime(300))
      .subscribe(this.onStoreUpdate);
    this.createStoreSubscriptions();

    this.getInitialLineItems();
  }

  getInitialLineItems() {
    // this component is recreated every time it is opened
    // but the SET_ALL update type is called once,
    // so we need to set the line items on every component creation
    this.store
      .select(getLineItemsExtended)
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value) => !!value),
        debounceTime(300),
        take(1),
      )
      .subscribe((lineItems) => {
        this.setAllLineItems(lineItems);
      });
  }

  createStoreSubscriptions() {
    this.projectStartDate$.pipe(takeUntil(this.isDestroyed$)).subscribe((date) => {
      this.projectStartDate = date;
    });

    this.selectedYear$.pipe(takeUntil(this.isDestroyed$)).subscribe((year) => {
      this.selectedYear = year;
    });

    this.committedItems$.pipe(takeUntil(this.isDestroyed$)).subscribe((items) => {
      this.committedItems = items;
    });
  }

  getLineWithDisabledMonths(item: ILineItemExtended) {
    item = cloneDeep(item);
    return item;
  }

  onStoreUpdate = (storeUpdate: ILastSpendStoreUpdate) => {
    switch (storeUpdate.lastStoreUpdate.type) {
      case SpendStoreUpdateTypes.SET_ALL: {
        this.setAllLineItems(storeUpdate.lineItems);
        break;
      }
      case SpendStoreUpdateTypes.ADD: {
        const localLine = this.getLineWithDisabledMonths(storeUpdate.lineItems[0]);
        this.items.push(localLine);
        break;
      }
      case SpendStoreUpdateTypes.UPDATE: {
        this.updateLineLocally(storeUpdate.lineItems[0]);
        break;
      }
      case SpendStoreUpdateTypes.DELETE: {
        this.deleteLineLocally(storeUpdate.lastStoreUpdate.lineId);
        break;
      }
      case SpendStoreUpdateTypes.FILTER_CHANGE: {
        storeUpdate.lineItems.forEach((item) => {
          this.updateLineLocally(item);
        });
        break;
      }
    }
    this.changeDetectorRef.detectChanges();
  };

  updateLineLocally(item: ILineItemExtended) {
    const newItem = cloneDeep(item);
    const ind = this.items.findIndex((it) => it.id === newItem.id);
    if (ind < 0) {
      return;
    }
    this.setModifiedItem(ind, newItem);
  }

  deleteLineLocally(id: number) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  /**
   * Updates an item in the list at the given index.
   * It is intentionally written this way listing the properties one by one
   * to avoid creating a new object thus improving performance.
   */
  setModifiedItem(index: number, newItem: ILineItemExtended) {
    this.items[index].id = newItem.id;
    this.items[index].name = newItem.name;
    this.items[index].project_total = newItem.project_total;
    this.items[index].year_total = newItem.year_total;
    this.items[index].start_date = newItem.start_date;
    this.items[index].commitment_start_date = newItem.commitment_start_date;
    this.items[index].duration = newItem.duration;
    this.items[index].distribution = newItem.distribution;
    this.items[index].budget = newItem.budget;
    this.items[index].row_number = newItem.row_number;
    this.items[index].committed_items = newItem.committed_items;
    Object.entries(this.items[index].monthly_data).forEach(([key, value]) => {
      if (value !== newItem.monthly_data[key]) {
        this.items[index].monthly_data[key] = cloneDeep(newItem.monthly_data[key]);
      }
    });
  }

  setAllLineItems(lineItems: ILineItemExtended[]) {
    // UI does not freeze this way
    setTimeout(() => {
      this.items = cloneDeep(lineItems);
      this.changeDetectorRef.detectChanges();
    }, 10);
  }

  setRowNumberUntilIndex(index: number) {
    this.items.forEach((item, lineIndex) => {
      if (lineIndex <= index) {
        item.row_number = lineIndex;
        this.store.dispatch(updateLineItem({ lineItem: DeepCopyService.deepCopy(item) }));
      }
    });
  }

  trackByItemId = (_: number, item: ILineItemExtended | ICommittedItemExtended) => item.id;

  drop(event: CdkDragDrop<ILineItemExtended, any>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    const newItems = this.items.map((item, lineIndex): ILineItem => {
      return {
        ...this.spentDistributionService.extendedItemToOriginal(item),
        row_number: lineIndex,
      };
    });

    this.store.dispatch(updateLineItemsBulk({ lineItems: newItems }));
  }

  dropCommittedItem(event: CdkDragDrop<ILineItem, any>, item: ILineItemExtended) {
    const lastAllowedIndex = item.committed_items.findIndex(
      (item) => item.type === 'forecast_modification',
    );

    if (lastAllowedIndex !== -1 && event.currentIndex >= lastAllowedIndex) {
      event.currentIndex = lastAllowedIndex - 1;
    }

    moveItemInArray(item.committed_items, event.previousIndex, event.currentIndex);

    const committedItems = item.committed_items.map((committedItem, lineIndex): ICommittedItem => {
      return {
        ...this.spentDistributionService.extendedCommitmentItemToOriginal(committedItem),
        committed_row_number: lineIndex,
      };
    });

    this.store.dispatch(updateCommittedLineItemsBulk({ committedLineItems: committedItems }));
  }

  dropCommittedItemOnly(event: CdkDragDrop<ICommittedItemExtended, any>) {
    const lastAllowedIndex = this.committedItems.findIndex(
      (item) => item.type === 'forecast_modification',
    );

    if (lastAllowedIndex !== -1 && event.currentIndex >= lastAllowedIndex) {
      event.currentIndex = lastAllowedIndex - 1;
    }

    moveItemInArray(this.committedItems, event.previousIndex, event.currentIndex);

    const committedItems = this.committedItems.map((committedItem, lineIndex) => {
      return {
        ...this.spentDistributionService.extendedCommitmentItemToOriginal(committedItem),
        row_number: lineIndex,
      };
    });

    this.store.dispatch(updateCommittedLineItemsBulk({ committedLineItems: committedItems }));
  }
}
