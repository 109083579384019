<div *ngIf="!(isSidebarLoading$ | async); else loadingScreen" class="h-full flex flex-col">
  <div class="bg-white">
    <app-commitment-sidebar-title
      [showBackButton]="!isEdit || (currentPage$ | async) !== COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE"
      [title]="isEdit ? 'Edit Change Order' : 'Add Change Order'"
      (goBack)="goBack()"
      (needHelpClicked)="needHelpClicked($event)"
      [showHelpButton]="true"
    ></app-commitment-sidebar-title>
  </div>

  <ng-container *ngIf="showHelp; then help; else generalContent"></ng-container>
  <ng-template #help>
    <div class="px-7 bg-shade_7 h-full">
      <app-commitments-sidebar-help
        [type]="COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER"
      ></app-commitments-sidebar-help>
    </div>
  </ng-template>

  <ng-template #generalContent>
    <app-commitment-entry-text
      *ngIf="(currentPage$ | async) === 1"
      entryType="GeneralInfo"
      text="General Information"
    ></app-commitment-entry-text>

    <ng-scrollbar
      [ngClass]="{ 'first-page': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
      class="std-scrollbar wider-track"
      appearance="compact"
      orientation="vertical"
    >
      <div
        [ngClass]="{ 'mb-5 mt-4 horizontal-spacing': (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE }"
        class="scrollable-content"
      >
        <form
          class="flex flex-col flex-1"
          #firstPageForm="ngForm"
          role="form"
          *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE; else secondPage"
          [@fadeInGeneral]
        >
          <div class="flex-1 flex flex-col">
            <app-upload-commitments-sidebar
              [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER"
            ></app-upload-commitments-sidebar>

            <app-dropdown
              id="change_order_type"
              name="change_order_type"
              label="Change Order Type"
              placeholder="Change Order Type"
              [(model)]="model.change_order_type"
              class="w-full"
              [required]="true"
              (modelChange)="updateChangeOrder('change_order_type')"
              [options]="changeOrderTypeOptions"
              optionLabel="label"
              optionValue="value"
              [disabled]="isEdit"
            ></app-dropdown>

            <app-floating-input
              id="title"
              label="Change Order Title"
              placeholder="Change Order Title"
              name="title"
              (change)="updateChangeOrder()"
              [(model)]="model.title"
              class="w-full"
              required
            ></app-floating-input>

            <div class="two-input-row">
              <div
                (click)="isDropdownShown.service_provider = true"
                class="w-full p-float-label p-input-icon-right mb-2 mt-6 cursor-pointer"
              >
                <input
                  pInputText
                  required
                  readonly
                  #optionsTrigger="cdkOverlayOrigin"
                  cdkOverlayOrigin
                  class="w-full cursor-pointer pr-8 overflow-hidden text-nowrap text-ellipsis"
                  id="serviceProviderUserId"
                  name="serviceProviderUserId"
                  #serviceProviderModel="ngModel"
                  [(ngModel)]="model.service_provider.name"
                  [ngClass]="{
                    'ng-invalid ng-dirty': serviceProviderModel.invalid && serviceProviderModel.touched
                  }"
                />
                <label>Company Name</label>
                <small
                  [ngClass]="{
                    hidden: serviceProviderModel.valid || serviceProviderModel.untouched
                  }"
                  class="p-error text-uppercase"
                  >REQUIRED</small
                >
                <i
                  class="-mt-[4px]"
                  [ngClass]="isDropdownShown.service_provider ? 'dropdown-icon opened' : 'dropdown-icon closed'"
                ></i>

                <app-options-list-general
                  [(show)]="isDropdownShown.service_provider"
                  [trigger]="optionsTrigger"
                  [overlayPositions]="dropdownOverlayPositions"
                >
                  <app-company-dropdown
                    (optionSelected)="selectedCompany($event, serviceProviderModel)"
                    [hideAddCompany]="true"
                  ></app-company-dropdown>
                </app-options-list-general>
              </div>

              <app-dropdown
                optionLabel="title"
                optionValue="id"
                [disabled]="
                  (hasCommitments$ | async) ||
                  (!user.isManager && model?.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED)
                "
                [required]="true"
                placeholder="Contract"
                label="Contract"
                id="contract_id"
                name="contract_id"
                [(model)]="model.contract_id"
                (modelChange)="updateChangeOrder(); loadContractSummaryDetails()"
                [options]="filteredContractsByCompany"
                class="w-full"
              >
              </app-dropdown>
            </div>

            <div class="flex justify-between items-center gap-2.5">
              <app-input-calendar
                class="w-full"
                [required]="true"
                [isDisabled]="hasCommitments$ | async"
                [date]="model.added_at"
                [minStartDate]="(selectedContract$ | async)?.contract?.added_at ?? projectData?.start_date"
                (dateChanged)="updateChangeOrderDate($event)"
                [iconClass]="{
                  iconPosition: 'p-input-icon-right',
                  icon: 'icon-calendar-box text-color_secondary'
                }"
                placeholder="Change Order Date"
              ></app-input-calendar>

              <app-dropdown
                [optionLabel]="null"
                [optionValue]="null"
                [disabled]="
                  (hasCommitments$ | async) ||
                  (!user.isManager && model?.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED)
                "
                [required]="true"
                placeholder="Change Order Status"
                label="Change Order Status"
                id="approval_status"
                name="approval_status"
                [(model)]="model.approval_status"
                (modelChange)="updateChangeOrder()"
                [options]="getApprovalStatuses()"
                class="w-full"
              >
                <ng-template #selectedContent #optionContent let-option>
                  <div class="capitalize text-color_primary text-medium">{{ option }}</div>
                </ng-template>
              </app-dropdown>
            </div>

            <app-floating-textarea
              [autoResize]="false"
              class="w-full"
              id="description"
              name="description"
              [(model)]="model.description"
              (change)="updateChangeOrder()"
              label="Change Order Description (optional)"
              placeholder="Change Order Description (optional)"
            ></app-floating-textarea>
          </div>
        </form>

        <!--        SECOND PAGE-->

        <ng-template #secondPage>
          <div [@fadeInGeneral]>
            <div *ngFor="let entry of spendEntriesData; let index = index; trackBy: trackByIndex">
              <app-spend-entry
                #spendEntries
                [commitmentAddedAt]="model.added_at"
                [model]="entry"
                [projectData]="projectData"
                [index]="index"
                [commitmentType]="COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER"
                [disableAddDescription]="model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id"
                [disableRemovedEntry]="model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id"
                [disableBudgetTagDropdown]="
                  model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id
                "
                [disabledAddDescriptionTooltip]="
                  model.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED && !!model?.id
                    ? 'Cannot add description to an approved change order'
                    : 'Select a line item first'
                "
                [changeOrderType]="model.change_order_type"
              ></app-spend-entry>
            </div>
            <div class="horizontal-spacing mt-5 mb-2">
              <app-simple-button
                size="large"
                [wrapperClass]="{
                  'border-2 bg-transparent w-full rounded-[3px] ': true,
                  'border-color_secondary text-color_secondary': (canAddChangeOrderSpendEntries$ | async),
                  'border-shade_3 text-shade_3': !(canAddChangeOrderSpendEntries$ | async)
                }"
                (click)="addSpendEntry()"
                [disabled]="!(canAddChangeOrderSpendEntries$ | async)"
                >Add Spend Entry
              </app-simple-button>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-scrollbar>

    <div
      class="bottom-container"
      [ngClass]="{ bordered: (currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE }"
    >
      <span
        *ngIf="(currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.LAST_PAGE"
        class="text-shade_1 text-base font-bold flex flex-1"
      >
        <span class="flex-1">Total Change Order</span>
        <span class="flex-1 justify-center flex" [ngClass]="{ negative: (totalChangeOrderCost$ | async) < 0 }">
          <span *ngIf="(totalChangeOrderCost$ | async) !== 0">{{
            totalChangeOrderCost$ | async | money: 'positiveComa'
          }}</span>
          <span *ngIf="(totalChangeOrderCost$ | async) === 0">$ {{ totalChangeOrderCost$ | async | number }}</span>
        </span>
      </span>

      @if ((currentPage$ | async) === COMMITMENT_SIDEBAR_PAGE.FIRST_PAGE) {
        <app-simple-button
          [wrapperClass]="{
            rounded: true
          }"
          (click)="incrementPage()"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading">Next</span>
          <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
        </app-simple-button>
      } @else {
        <app-simple-button
          (click)="save()"
          [disabled]="isLoading"
          [wrapperClass]="{
            'bg-color_accent rounded': true
          }"
        >
          <span *ngIf="!isLoading">Submit</span>
          <span *ngIf="isLoading" class="icon-refresh animate-spin"></span>
        </app-simple-button>
      }
    </div>
  </ng-template>
</div>

<ng-template #loadingScreen>
  <div class="flex w-full h-full items-center justify-center">
    <div class="notify-msg animate-pulsating-border">
      <div class="icon-logo-box-hex"></div>
    </div>
  </div>
</ng-template>
