<div class="interaction-bar-wrapper">
  <div class="interaction-bar-title">Project Invite</div>

  <div class="interaction-bar-content">
    <div class="header">
      <div class="title">
        <app-faded-text>{{ title }}</app-faded-text>
      </div>
    </div>
    <div class="subtitle">PROJECT NAME</div>
    <div class="projects-container" [ngClass]="{ 'center-container': loading }">
      <ng-scrollbar orientation="vertical" class="std-scrollbar">
        <mat-selection-list #projectsSelector>
          <mat-list-option
            *ngFor="let project of projects"
            [value]="project.id"
            checkboxPosition="before"
            disableRipple="true"
          >
            {{ project.title }}
          </mat-list-option>
        </mat-selection-list>
      </ng-scrollbar>

      <div *ngIf="loading" class="icon-refresh animate-spin notify-text"></div>
      <div class="notify-text" *ngIf="projects.length === 0 && !loading">No bidding projects</div>
    </div>
    <!--    <app-calendar #schedule (events)="registerEvents($event)" (dateChange)="registerNewDate($event)"></app-calendar>-->
    <!--    <app-selection-list (changes)="refreshCalendar()" [date]="calendarDate" [events]="calendarEvents"-->
    <!--                        [intervalMin]="30"></app-selection-list>-->
    <div class="invite-actions">
      <!--      <button mat-button class="btn-invite-all" (click)="inviteAllToProject()">Invite All</button>-->
      <button mat-button class="btn-invite" (click)="inviteToProject()">Send invite</button>
    </div>
  </div>
</div>
