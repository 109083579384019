<app-general-sidebar-header
  [showBackButton]="true"
  (goBack)="onBack()"
  title="Budget Adjustment"
  subtitle="Budget Adjustment"
></app-general-sidebar-header>
<ng-scrollbar class="std-scrollbar flex-1" appearance="native" orientation="vertical">
  <div class="horizontal-spacing scrollable-content">
    <form
      name="budgetAdjustmentForm"
      role="form"
      #budgetAdjustmentForm="ngForm"
      class="h-full flex flex-col flex-1 p-float-label px-7"
    >
      <app-floating-input
        name="value"
        [(model)]="model.value"
        placeholder="Dollar Adjustment"
        label="Dollar Adjustment"
        [formatMoney]="true"
        [ngClass]="{
          'negative-color': model.value < 0
        }"
        required
      ></app-floating-input>

      <app-dropdown
        required
        [(model)]="model.from_item_id"
        [options]="fromItems"
        [optionValue]="'id'"
        [optionLabel]="'name'"
        label="From Budget Line"
        placeholder="From Budget Line"
        name="from_item_id"
      >
      </app-dropdown>

      <app-dropdown
        required
        name="to_item_id"
        [(model)]="model.to_item_id"
        [options]="toItems"
        [optionValue]="'id'"
        [optionLabel]="'name'"
        (modelChange)="towardsItemChange($event)"
        [showLabel]="true"
        label="Towards Budget Line"
        placeholder="Towards Budget Line"
      >
        <ng-template #selectedContent let-option>
          @if (option.id === ADD_NEW_ITEM_ID) {
            <span>Add New Budget Line </span>
          } @else {
            <div>{{ option.name }}</div>
          }
        </ng-template>
        <ng-template #optionContent let-option>
          @if (option.id === ADD_NEW_ITEM_ID) {
            <span [ngClass]="{ 'disabled color-disabled pointer-events-none': hasBudgetTemplate$ | async }">
              <span
                [ngClass]="{ 'color-disabled': hasBudgetTemplate$ | async }"
                class="icon-add-round text-color_secondary mr-1"
              ></span>
              <span class="text-color_secondary" [ngClass]="{ 'color-disabled': hasBudgetTemplate$ | async }"
                >Add New Budget Line
              </span>
            </span>
          } @else {
            <div>{{ option.name }}</div>
          }
        </ng-template>
      </app-dropdown>

      <div class="flex flex-col justify-between">
        @if (isNewItemInput) {
          <div class="flex gap-2.5">
            <app-floating-input
              class="w-full"
              name="new_item_name"
              *ngIf="isNewItemInput"
              [(model)]="model.new_item.name"
              placeholder="Budget Line Name"
              label="Budget Line Name"
              [ngClass]="{ disabled: hasBudgetTemplate$ | async }"
              required
            ></app-floating-input>

            <app-input-calendar
              class="w-full"
              [minStartDate]="(project$ | async).start_date"
              [(date)]="model.new_item.start_date"
              name="start_date"
              placeholder="Start Date"
              readonly
              required
            ></app-input-calendar>
          </div>

          <div class="flex gap-2.5">
            <app-dropdown
              class="w-full"
              optionValue="key"
              optionLabel="name"
              name="distribution_type"
              label="Distribution"
              placeholder="Distribution"
              [(model)]="model.new_item.distribution"
              [options]="distributionTypes"
              [showError]="false"
              required
            ></app-dropdown>

            <app-dropdown
              class="w-full"
              name="duration"
              [(model)]="model.new_item.duration"
              [options]="durationMonths"
              label="Duration"
              placeholder="Duration"
              required
            ></app-dropdown>
          </div>
        }
      </div>

      <app-floating-textarea
        class="pr-0.5 pl-0.5 flex-1 min-h-0"
        label="Adjustment reason & notes"
        placeholder="Adjustment reason & notes"
        name="note"
        [(model)]="model.note"
        [autoResize]="false"
        required
      ></app-floating-textarea>
    </form>
  </div>
</ng-scrollbar>

<div class="flex items-center justify-between ml-auto mb-7 mr-5 mt-4 pl-8 pr-4 gap-4">
  <app-simple-button
    [wrapperClass]="{
      'rounded text-sm font-semibold bg-color_accent': true
    }"
    class="flex ml-auto"
    (click)="save()"
  >
    Save
  </app-simple-button>
</div>
