import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { DropdownComponent } from '../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../inputs/floating-input/floating-input.component';
import { FloatingTextareaComponent } from '../inputs/floating-textarea/floating-textarea.component';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { NgForm, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { viewProjectSelectors } from '../../store/view-project/view-project.selectors';
import { NotificationsService } from '../../services/notifications.service';
import { getAllLineItemData } from '../../store/spend/spend.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GeneralSidebarHeaderComponent } from '../general-sidebar-header/general-sidebar-header.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { SimpleButtonComponent } from '../buttons/simple-medium-button/simple-button.component';
import { ProjectSpendService } from '../../services/project-spend.service';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { IBudgetAdjustmentPayload, Items } from './budget-adjustment.types';
import { distributionTypes } from '../constants/spend.constants';
import { InputCalendarComponent } from '../inputs/input-calendar/input-calendar.component';
import { Project } from '../../pages/webapp/projects/projects.interface';
import { INTERACTION_BAR_STATES } from '../constants/interaction-bar.constants';
import { MANAGE_PROJECT_TABS } from '../constants/view-project.constants';

@Component({
  selector: 'app-budget-adjustment',
  standalone: true,
  imports: [
    DropdownComponent,
    FloatingInputComponent,
    FloatingTextareaComponent,
    MatButton,
    MatRipple,
    NgIf,
    PaginatorModule,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
    GeneralSidebarHeaderComponent,
    NgScrollbar,
    SimpleButtonComponent,
    InputCalendarComponent,
  ],
  templateUrl: './budget-adjustment.component.html',
  styleUrl: './budget-adjustment.component.scss',
})
export class BudgetAdjustmentComponent implements AfterViewInit {
  @ViewChild('budgetAdjustmentForm') budgetAdjustmentForm: NgForm;
  isNewItemInput: boolean = false;
  protected readonly isLoading = false;

  store = inject(Store);
  notif = inject(NotificationsService);
  projectSpendService = inject(ProjectSpendService);
  interactionBar = inject(InteractionBarStateService);

  model = {
    from_item_id: null,
    to_item_id: null,
    value: null,
    note: null,
    new_item: {
      name: null,
      start_date: null,
      duration: null,
      distribution: null,
    },
  };

  EXTERNAL_ADJUSTMENT = 'External Adjustment';
  ADD_NEW_ITEM_ID = -1;

  project: Project;
  protected readonly distributionTypes = distributionTypes;
  durationMonths = Array(72)
    .fill(0)
    .map((_, i) => i + 1)
    .map((i) => {
      return {
        label: i === 1 ? `${i} month` : `${i} months`,
        value: i,
      };
    });
  toItems: Items[] = [{ id: this.ADD_NEW_ITEM_ID, name: 'Add new Budget Line', disabled: true }];
  fromItems: Items[] = [
    {
      id: this.EXTERNAL_ADJUSTMENT,
      name: this.EXTERNAL_ADJUSTMENT,
    },
  ];

  hasBudgetTemplate$ = this.store.select(viewProjectSelectors.hasTemplate);
  lineItems$ = this.store.select(getAllLineItemData);
  project$ = this.store.select(viewProjectSelectors.getSelectedProject);
  isDestroyed$ = new Subject<boolean>();

  ngAfterViewInit() {
    this.hasBudgetTemplate$.pipe(takeUntil(this.isDestroyed$)).subscribe((hasTemplate) => {
      this.toItems[0].disabled = hasTemplate;
    });
    this.lineItems$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((lineItems) => lineItems?.length > 0),
        take(1),
      )
      .subscribe((lineItems) => {
        this.fromItems = [...this.fromItems, ...lineItems];
        this.toItems = [...this.toItems, ...lineItems];
      });
    this.project$.pipe(takeUntil(this.isDestroyed$)).subscribe((project) => {
      this.project = project;
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  private validAdjustmentNumber() {
    if (this.model.value > 0) {
      return true;
    }
    return this.model.value < 0 && this.model.from_item_id === this.EXTERNAL_ADJUSTMENT;
  }

  towardsItemChange(selectedId: number) {
    this.isNewItemInput = selectedId === this.ADD_NEW_ITEM_ID;
    if (selectedId !== this.ADD_NEW_ITEM_ID) {
      this.resetNewLineItem();
    }
  }

  save() {
    this.budgetAdjustmentForm.form.markAllAsTouched();

    if (this.budgetAdjustmentForm.form.invalid) {
      this.notif.showError('Please fill all required fields');
      return;
    }

    if (!this.validAdjustmentNumber()) {
      this.notif.showError('Negative numbers are allowed only from External Adjustment!');
      return;
    }

    this.createBudgetAdjustment();
  }

  private createBudgetAdjustment() {
    this.notif.showLoading();
    const rawData: Partial<IBudgetAdjustmentPayload> = this.transformDataForBackend();
    this.projectSpendService.adjustBudget(rawData).then(
      (data) => {
        console.log(data);
        this.notif.showSuccess('Budget adjusted!');
        setTimeout(() => {
          this.interactionBar.close();
        }, 500);
      },
      (err) => this.notif.showError(err),
    );
  }

  private transformDataForBackend() {
    let payload: Partial<IBudgetAdjustmentPayload> = {
      ...this.model,
      project_id: this.project.id,
      value: Number(this.projectSpendService.formatString(this.model.value)),
      is_external_adjustment: this.model.from_item_id === this.EXTERNAL_ADJUSTMENT,
    };

    // if external adjustment, from_item_id has to be equal to to_item_id
    if (payload.is_external_adjustment) {
      payload.from_item_id = payload.to_item_id;
    }

    if (this.isNewItemInput) {
      delete payload.to_item_id;
    } else {
      delete payload.new_item;
    }

    if (this.isNewItemInput && payload.is_external_adjustment) {
      delete payload.from_item_id;
    }

    return payload;
  }

  private resetNewLineItem() {
    this.model.new_item = {
      name: null,
      start_date: null,
      duration: null,
      distribution: null,
    };
  }

  onBack() {
    this.interactionBar.openInteractionBar(INTERACTION_BAR_STATES.MANAGE_PROJECT, {
      tab: MANAGE_PROJECT_TABS.BUDGET_CASHFLOW,
    });
  }
}
