<div class="header-wrapper">
  <div class="head-row left-side">
    <div class="titles-rows">
      <app-dropdown
        [(model)]="templateId"
        (modelChange)="setTemplate($event)"
        [disabled]="
          (hasCommitments$ | async) ||
          (selectedBudgetType$ | async) !== SPEND_TYPES.BUDGET ||
          (areTemplatesLoading$ | async)
        "
        [pTooltip]="(hasCommitments$ | async) ? 'Template cannot be changed after commitments have been added.' : ''"
        name="template_dropdown"
        appendTo="body"
        class="spend-app-dropdown mod-spend-bg"
        optionValue="id"
        optionLabel="name"
        placeholder="Select Template"
        [options]="templates"
        [showLabel]="false"
        [showError]="false"
      >
        <ng-template #optionContent let-option>
          <div class="font-semibold text-sm text-color_primary">
            {{ option?.name }}
          </div>
        </ng-template>
        <ng-template #selectedContent let-option>
          <div class="font-semibold text-sm text-color_primary selected">
            @if (areTemplatesLoading$ | async) {
              Loading... <span class="icon-refresh animate-spin"></span>
            } @else if (templateId === DEFAULT_TEMPLATE_ID) {
              {{ DEFAULT_TEMPLATE_NAME }}
            } @else {
              {{ option?.name }}
            }
          </div>
        </ng-template>
      </app-dropdown>
    </div>
    <div [pTooltip]="dollarTooltipContent" tooltipPosition="bottom" class="budget-dollars">
      <span> Dollars </span>
      <span class="icon-info-filled"></span>
    </div>
    <ng-template #dollarTooltipContent>
      <div class="max-w-44">
        Each line item is independent of one another. <br />
        The Budget line amount is not a sum of the Service Provider lines items.
      </div>
    </ng-template>
  </div>
  <div class="head-row right-side">
    <div [pTooltip]="distributionTooltip" tooltipPosition="bottom" class="distribution-levers">
      <span> Cashflow Distribution Levers </span>
      <span class="icon-info-filled"></span>
    </div>

    <ng-template #distributionTooltip>
      For contracts, the start date and duration can only be adjusted from editing a contract under the "commitments"
      page.
    </ng-template>

    <app-dropdown
      (modelChange)="setSelectedYear($event)"
      [model]="selectedYear$ | async"
      [options]="possibleYearsOptions"
      [showError]="false"
      [showLabel]="false"
      appendTo="body"
      class="spend-app-dropdown pl-4"
      name="year-column fiscal_year_dropdown"
      optionValue="value"
      optionLabel="key"
    >
      <ng-template #selectedContent let-option>
        <div class="font-semibold text-sm text-color_primary dropdown-item">
          <span> FY{{ option?.key }} </span>
        </div>
      </ng-template>
      <ng-template #optionContent let-option>
        <div
          class="dropdown-item selected"
          [ngClass]="option?.value === ADD_YEAR ? 'text-color_secondary' : 'text-color_primary'"
        >
          {{ option?.key }}
        </div>
      </ng-template>
    </app-dropdown>

    <app-dropdown
      (modelChange)="setBudgetType($event)"
      [disabled]="!(hasCommitments$ | async) || (areTemplatesLoading$ | async)"
      [model]="selectedBudgetType$ | async"
      name="budget_dropdown"
      appendTo="body"
      class="spend-app-dropdown w-48 ml-4"
      [options]="spendBudgetTypes"
      [optionLabel]="null"
      [optionValue]="null"
      [showLabel]="false"
      [showError]="false"
    >
      <ng-template #selectedContent #optionContent let-option>
        <div class="font-semibold text-sm text-color_primary w-full">{{ option }}</div>
      </ng-template>
    </app-dropdown>
    <app-simple-button
      size="large"
      [wrapperClass]="{
        'bg-white border rounded border-solid border-color_secondary text-color_secondary text-sm font-semibold': true
      }"
      class="ml-auto flex items-center justify-end pr-2.5 min-w-max"
      (click)="openSpreadsheet()"
    >
      Export Spreadsheet
    </app-simple-button>
  </div>
</div>
