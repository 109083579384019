<div
  [ngClass]="{
    'opacity-0 pointer-events-none': (areTemplatesLoading$ | async) || hideTotals,
    hidden: hideTotals
  }"
  class="form lines-wrapper w-fit 2xl:w-auto"
>
  <div class="left">
    <div class="add-line column form" [ngClass]="{ 'p-disabled disabled': disableOnTemplate$ | async }">
      @if (!templateId) {
        <span
          [ngClass]="{
            'invisible pointer-events-none': selectedBudgetType !== SPEND_TYPES.BUDGET
          }"
          class="cursor-pointer pl-1"
          (click)="addLineToStore()"
        >
          Add Budget Line
        </span>
      }
    </div>

    <div class="budget-area column column-header total-row">
      <div class="input total-year">
        <app-spend-custom-input
          [disabled]="true"
          class="app-custom-input bg-[transparent]"
          [value]="totals.project_total"
        >
        </app-spend-custom-input>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="distribution-type-area total-row">
      <div class="column input"></div>
      <div class="column input"></div>
      <div class="column input"></div>
    </div>
    <div class="months total-row">
      <div class="input-wrapper input total-year column">
        <app-spend-custom-input
          class="app-custom-input bg-[transparent]"
          [allowNegatives]="true"
          [disabled]="true"
          [value]="totals.year_total"
        >
        </app-spend-custom-input>
      </div>

      <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
        <div class="input">
          <app-spend-custom-input
            class="app-custom-input bg-[transparent]"
            [allowNegatives]="true"
            [disabled]="true"
            [value]="totals.monthly_data[month]"
          >
          </app-spend-custom-input>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form lines-wrapper total-row w-fit 2xl:w-auto">
  <div class="left">
    <div class="form column titles-column">
      <div class="input h-txt-overflow"></div>
    </div>
    <div class="form column budget-area">
      <div class="input total-year"></div>
    </div>
  </div>
  <div class="right">
    <div class="distribution-type-area">
      <div class="column input"></div>
      <div class="column input"></div>
      <div class="column input"></div>
    </div>
    <div class="months">
      <div class="input-wrapper input total-year column"></div>

      <div *ngFor="let month of MONTHS_KEYS; trackBy: trackByIndex" class="input-wrapper month-input column">
        <div class="input"></div>
      </div>
    </div>
  </div>
</div>
